<template>
  <div class="my-account-login">
    <HeroImage :bg-image="'images/2-service-pages-hero.jpg'" :has-purple-bg="false">
      <!-- <div class="hero-title has-text-centered">
        <h3 class="title is-2 pt-6 txt-white">
          Bienvenido, {{user.first_name}}
        </h3>
        <a href="#" class="txt-white is-underlined" @click="logout()">Cerrar sessión</a>
      </div> -->
    </HeroImage>
    <section class="over-hero">
      <div class="container is-fullhd">
        <div class="my-account-wrap">
          <div class="columns content-my-account-wrap">
            <clinic-menu></clinic-menu>
            <div id="my-locations" class="column is-12 right-column">
              <h3 class="title is-4 pb-4 txt-c-mobile">Mis direcciones</h3>
              <div class="columns is-variable is-3 is-flex-wrap-wrap">
                <template v-for="location in locations">
                  <div v-bind:key="location.id" class="column is-6 mb-1">
                    <div class="has-background-light location-box">
                      <p class="pb-4"><strong class="txt-yellow is-uppercase" v-if="location.primary">Dirección principal</strong></p>
                      <p>
                        <strong>{{location.address.clinic_name}}</strong><br>
                        <strong>{{location.address.name}}</strong><br>
                        {{location.address.address1}}<br>
                        {{location.address.zip}} {{location.address.city}} ({{location.address.province}}), {{location.address.country}}<br>
                        {{location.address.phone}}<br><br>
                        <a href="#" class="is-underlined mr-2" @click.prevent="editLocation(location)">Editar</a>
                        <a href="#" class="is-underlined mr-2" @click.prevent="deleteLocation(location)">Eliminar</a>
                        <a href="#" class="is-underlined" v-if="!location.primary" @click.prevent="setMainLocation(location)">Seleccionar como principal</a>
                      </p>
                    </div>
                  </div>
                </template>

                <div class="column is-6 mb-1">
                  <div class="has-background-light location-box is-empty">
                    <button @click="toggleAddLocation()"><span class="icon-filters-open" v-if="!isAddLocationOpen"></span><span class="icon-close" v-if="isAddLocationOpen"></span></button>
                  </div>
                </div>
              </div>

              <h3 id="new-location" class="title is-4 pb-4 pt-4" v-if="isAddLocationOpen">Añadir una nueva dirección</h3>
              <h3 id="new-location" class="title is-4 pb-4 pt-4" v-if="isEditMode">Actualizar la dirección</h3>
              <edit-address v-if="isAddLocationOpen || isEditMode" :is-edit-mode="isEditMode" :location-to-edit="clinic_address" :callback="closeEdit"></edit-address>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeroImage from '@/components/HeroImage.vue'
import Footer from '@/components/Footer.vue'
import ClinicMenu from '@/components/Clinic/ClinicMenu.vue'
import EditAddress from '@/components/Clinic/EditAddress.vue'
import { ElemOffset } from '@/utils/utils'
import { mapGetters } from 'vuex'
export default {
  name: 'ClinicAddresses',
  components: {
    HeroImage,
    Footer,
    ClinicMenu,
    EditAddress
  },
  data () {
    return {
      clinic_address: null,
      isAddLocationOpen: false,
      isEditMode: false
    }
  },
  computed: {
    ...mapGetters({
      clinic: 'user/clinic',
      user: 'user/user',
      locations: 'clinic/locations'
    })
  },
  methods: {
    closeEdit () {
      this.isEditMode = false
      this.isAddLocationOpen = false
      this.clinic_address = null
      // const self = this
      this.$nextTick(function () {
        const myAddressesElement = document.getElementById('my-locations')
        const myAddressesOffset = ElemOffset(myAddressesElement)
        window.scroll({
          top: myAddressesOffset.top,
          behavior: 'smooth'
        })
      })
    },
    editLocation (location) {
      this.isEditMode = true
      // this.clinic_address = JSON.parse(JSON.stringify(address))
      this.clinic_address = location
      // const self = this
      this.$nextTick(function () {
        const newAddressElement = document.getElementById('new-location')
        const elemOffset = ElemOffset(newAddressElement)
        window.scroll({
          top: elemOffset.top - 130,
          behavior: 'smooth'
        })
      })
    },
    deleteLocation (location) {
      const self = this
      if (location.primary) {
        this.$store.commit({
          type: 'application/ADD_FEEDBACK',
          feedback: {
            type: 'error',
            message: 'No puedes eliminar la dirección principal'
          }
        })
      } else {
        this.$store.dispatch('clinic/deleteLocation', {
          locationId: location.id,
          cb: (data) => {
            self.fetchLocations()
            console.log(data)
          },
          cbError: (error) => {
            self.errorMessage = '¡Error!'
            self.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'error',
                message: error
              }
            })
          }
        })
      }
    },
    setMainLocation (location) {
      const self = this
      location.primary = true
      this.updateLocation(location, () => {
        self.fetchLocations()
      })
    },
    updateLocation (location, cb = null) {
      const self = this
      this.$store.dispatch('clinic/updateLocation', {
        locationId: location.id,
        data: location,
        cb: (data) => {
          if (cb !== null) {
            cb()
          }
          console.log(data)
        },
        cbError: (error) => {
          self.errorMessage = '¡Error!'
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error
            }
          })
        }
      })
    },
    toggleAddLocation () {
      const self = this
      this.isEditMode = false
      this.isAddLocationOpen = !this.isAddLocationOpen
      this.clinic_address = null
      this.$nextTick(function () {
        const newAddressElement = document.getElementById('new-location')
        const myAddressesElement = document.getElementById('my-locations')
        if (self.isAddLocationOpen) {
          const elemOffset = ElemOffset(newAddressElement)
          window.scroll({
            top: elemOffset.top - 130,
            behavior: 'smooth'
          })
        } else {
          const myAddressesOffset = ElemOffset(myAddressesElement)
          window.scroll({
            top: myAddressesOffset.top,
            behavior: 'smooth'
          })
        }
      })
    },
    logout () {
      const self = this
      this.$store.dispatch('user/logOut', {
        cb: () => {
          self.goTo('/')
        },
        cbError: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: 'Error on the log out'
            }
          })
        }
      })
    },
    goTo (path) {
      this.$router.push({ path: path })
    },
    fetchLocations () {
      const self = this
      this.$store.dispatch('clinic/getLocations', {
        clinicId: this.clinic.id,
        cb: (data) => {
          console.log(data)
        },
        cbError: (error) => {
          self.errorMessage = '¡Error!'
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error
            }
          })
        }
      })
    }
  },
  created () {
    this.fetchLocations()
  }
}
</script>

<style scoped lang="scss">
.my-account-login{
  width: 100%;
  section{
    width: 100%;
    padding: 0px;
    &.over-hero{
      position: relative;
      margin-top: -430px;
      margin-bottom: 80px;
      z-index: 100;
    }
    .my-account-wrap{
      width: 100%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-my-account-wrap{
        padding: 0px;
        flex-direction: column;
        .has-background-light{
          border-radius: 4px 0px 0px 4px;
        }
        .right-column{
          padding: 80px;
          .location-box{
            min-height: 275px;
            border-radius: 8px;
            padding: 21px 28px;
            &.is-empty{
              position: relative;
              background-color: transparent;
              border: 3px solid #F6F4EF;
              button{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0px;
                margin: 0px;
                padding: 0px;
                background-color: transparent;
                cursor: pointer;
                &:hover{
                  span{
                    opacity: 1;
                  }
                }
                span{
                  opacity: .3;
                  font-size: 7rem;
                  transition: all .3s ease-out;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
